import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import ContentLoader from '../contentLoader/ContentLoader';
import BookingPersonCard from '../../../components/bookingPersonCard/BookingPersonCard';
import { useDispatch, useSelector } from 'react-redux';
import StatusModal from '../../../components/statusModal/StatusModal';
import { setAllBookings } from '../../../store/features/booking/bookingSlice';
import axios from 'axios';
import usePost from '../../../hooks/usePost';

const BookingDetail = () => {
    const { id } = useParams();
    const [trip, setTrip] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const { allTrips } = useSelector(state => state.trip);
    const { data, loading } = useFetch(`/getsinglebookingdetail.php?bid=${id}`);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeStatus, setActiveStatus] = useState(null);
    const [modalStatus, setModalStatus] = useState(null);
    const dispatch = useDispatch();
    const { post, loading: statusLoading } = usePost();


    useEffect(() => {
        if (data && data.booking[0]) {
            setActiveStatus(data.booking[0].status);
            setModalStatus(data.booking[0].status);
        }

        console.log(data);
    }, [data]);

    useEffect(() => {
        if (data && data.booking[0] && allTrips) {
            const foundTrip = allTrips.find(item => +item.id === +data.booking[0].tid);
            setTrip(foundTrip);
        }
    }, [data, allTrips]);

    useEffect(() => {
        let sum = 0;
        if (data && data.detail) {
            data.detail.forEach((person) => {
                sum += parseFloat(person.amount) || 0;
            });
        }
        setTotalAmount(sum);
    }, [data]);

    const updateStatus = async () => {
        await post("/updatebookingstatus.php", { id: id, status: modalStatus });
        const latestBookings = await axios.get(`${process.env.REACT_APP_BASE_URL}/getallbooking.php`);
        dispatch(setAllBookings(latestBookings.data));
        setActiveStatus(modalStatus);
        setIsModalOpen(false);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setModalStatus(activeStatus);  // Reset modalStatus to activeStatus
    }

    const handleButtonClick = (button) => {
        setModalStatus(button);
    };

    const showModal = () => {
        setIsModalOpen(true);
    }

    if (loading || !data || !trip) {
        return <ContentLoader />;
    }


    return (
        <div>
            {data && data.detail.length > 0 && trip ? (
                <div>
                    <h2 className='text-[20px] font-medium text-txtDark mb-3'>Booking Details</h2>
                    <div className='p-4 rounded-lg border-2 border-themeBlue relative'>
                        <p className='text-[16px] sm:text-[20px] font-medium text-themeBlue mb-3 '>Trip : {trip.title}</p>
                        <p className='text-[14px] sm:text-[14px] font-medium pb-1 border-b border-gray-400 mb-2'>OrderID : {data.booking[0].orderid ? data.booking[0].orderid : <span className='text-themeRed'>Not Paid</span>}</p>
                        <p className='text-[14px] sm:text-[14px] font-medium pb-1 border-b border-gray-400 mb-2 capitalize'>Customer : {data.booking[0].fname + " " + data.booking[0].lname} {data.booking[0].customerid === "0" && "(Guest)"} </p>
                        <p className='text-[14px] sm:text-[14px] font-medium pb-1 border-b border-gray-400 mb-2'>Email : {data.booking[0].email}</p>
                        <p className='text-[14px] sm:text-[14px] font-medium pb-1 border-b border-gray-400 mb-2'>Phone : {data.booking[0].phone}</p>
                        <p className='text-[14px] sm:text-[14px] font-medium pb-1 border-b border-gray-400 mb-2 capitalize'>Booking Status : {activeStatus}</p>
                        {data.booking[0].pname &&
                            <p className='text-[14px] sm:text-[14px] font-medium pb-1 border-b border-gray-400 mb-2 capitalize'>Pickup Point : {data.booking[0].pname}</p>
                        }
                        <div>
                            <button onClick={showModal} className='px-2 py-1 text-[16px] text-white bg-themeBlue rounded-md shadow-md absolute top-[8px] right-[8px]'>Change Status</button>
                        </div>
                    </div>
                    <h2 className='text-[18px] text-txtDark mt-5'>Persons Information</h2>
                    <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-5 my-5">
                        {data.detail.map((person) => (
                            <BookingPersonCard key={person.id} person={person} />
                        ))}
                    </div>
                    <h2 className='text-[18px] text-txtDark my-5'>Billing Information</h2>
                    <p className='text-[20px] font-semibold'>Total Amount : £{totalAmount}</p>
                    <StatusModal
                        isModalOpen={isModalOpen}
                        updateStatus={updateStatus}
                        activeStatus={modalStatus}
                        handleCancel={handleCancel}
                        handleButtonClick={handleButtonClick}
                        loading={statusLoading} // Assuming loading is defined somewhere in the scope
                    />
                </div>
            ) : (
                <div className='text-center py-3'>
                    <h3 className='text-3xl font-medium'>No Booking Found</h3>
                </div>
            )}
        </div>
    );
};

export default BookingDetail;
