import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    myAgency: [],
    activeAgency: null,
    agencyCurrentSub: null,
}

export const agencySlice = createSlice({
    name: "agency",
    initialState,
    reducers: {
        setMyAgency: (state, action) => {
            state.myAgency = action.payload;
        },
        setActiveAgency: (state, action) => {
            state.activeAgency = action.payload;
        },
        setAgencyCurrenSub: (state, action) => {
            state.agencyCurrentSub = action.payload;
        }
    }
});

export const { setMyAgency, setActiveAgency, setAgencyCurrenSub } = agencySlice.actions;
export default agencySlice.reducer;
