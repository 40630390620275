import React from 'react'
import { Link } from 'react-router-dom'

const PlanUpgradeMessage = () => {
    return (
        <div className='w-full py-2 px-4 rounded-xl text-white bg-themeRed mb-4'>
            <div className="flex justify-between items-center">
                <h2 className='text-[20px] leading-normal'>Upgrade plan to add new trips.</h2>
                <Link to="/subscription" className='decoration-none px-3 py-1 text-[18px] leading-normal font-medium border-2 border-white text-white bg-themeBlue rounded-xl'>Upgrade</Link>
            </div>
        </div>
    )
}

export default PlanUpgradeMessage