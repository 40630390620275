import React from 'react';
import { Layout } from 'antd';
import Sidebar from './sidebar/Sidebar';
import Topbar from './topbar/Topbar';
import MainContent from './mainContent/MainContent';

const Dashboard = () => {

  return (
    <Layout hasSider>
      <div className='hidden md:block'>
        <Sidebar />
      </div>
      <Layout className='ml-0 md:ml-[200px] h-screen'>
        <Topbar />
        <MainContent />
      </Layout>
    </Layout>
  );
};
export default Dashboard;