import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TrashedTripCard from '../../../components/trashedTripCard/TrashedTripCard';
import ContentLoader from '../contentLoader/ContentLoader';
import debounce from 'lodash.debounce'; // Ensure lodash.debounce is installed or use a custom debounce function

const TrashedTrip = () => {
    const { allTrips } = useSelector(state => state.trip);
    const [deletedTrips, setDeletedTrips] = useState([]);
    const [filteredTrips, setFilteredTrips] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (allTrips && allTrips.length > 0) {
            setDeletedTrips(allTrips.filter((trip) => +trip.isdeleted === +1));
        }
        setTimeout(() => {
            setLoading(false);
        }, 300);
    }, [allTrips]);

    // Debounced search function
    const handleSearch = debounce(() => {
        const lowercasedQuery = searchQuery.toLowerCase();
        const filtered = deletedTrips.filter(trip =>
            trip.title.toLowerCase().includes(lowercasedQuery)
        );
        setFilteredTrips(filtered);
    }, 300);

    useEffect(() => {
        handleSearch();
        return () => {
            handleSearch.cancel();
        };
    }, [searchQuery, deletedTrips, handleSearch]);

    if (loading) {
        return <ContentLoader />;
    }

    return (
        <div>
            <h2 className='text-[20px] font-medium text-txtDark'>Trashed Trip</h2>
            <div className="flex justify-end items-center">
                <input
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    type="text"
                    className='p-2 border-2 border-themeBlue bg-transparent rounded-md focus:outline-none'
                    placeholder='Search Trip'
                />
            </div>
            {filteredTrips && filteredTrips.length > 0 ? filteredTrips.map((trip) => (
                <TrashedTripCard key={trip.id} trip={trip} />
            )) :
                <div className='text-center py-3'>
                    <h3 className='text-3xl font-medium animate-fadeIn'>No Trip Found</h3>
                </div>
            }
        </div>
    );
}

export default TrashedTrip;
