import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Input, DatePicker, TimePicker, Button, Select, message, Tag } from 'antd';
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import usePost from '../../../hooks/usePost';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setAllTrips } from '../../../store/features/trip/tripSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import ContentLoader from '../contentLoader/ContentLoader';
import PlanUpgradeMessage from '../../../components/planUpgradeMessage/PlanUpgradeMessage';
import { getLogedInUser } from '../../../utils/functions';



const AddTrip = () => {

    const [form] = Form.useForm();
    const { post, loading } = usePost();
    const { tripCategories } = useSelector(state => state.trip);
    const dispatch = useDispatch();
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [editorHtml, setEditorHtml] = useState('');
    const { activeAgency } = useSelector(state => state.agency);
    const logedInUser = useMemo(() => getLogedInUser(), []);
    const [tripLimit, setTripLimit] = useState(null);
    const [limitLoading, setLimitLoading] = useState(true);

    const getLimit = useCallback(async () => {
        try {
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/gettriplimit.php`, { aid: activeAgency, uid: logedInUser.uid });
            setTripLimit(resp.data.data[0]);
            setLimitLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, [activeAgency, logedInUser]);

    useEffect(() => {
        getLimit();
    }, [getLimit])



    //method to add New trip 
    const addNewTrip = async (values) => {
        // Format dates and times before sending to the API
        const formattedValues = {
            ...values,
            startdate: values.startdate.format('YYYY-MM-DD'),
            starttime: values.starttime.format('HH:mm:ss'),
            enddate: values.enddate.format('YYYY-MM-DD'),
            endtime: values.endtime.format('HH:mm:ss'),
            includes: tags.map((item) => (item.name)).join(', '),
            aid: activeAgency.aid

        };

        try {

            await post('/addnewtrip.php', { ...formattedValues, aid: activeAgency });
            // updating the new trips to the redux
            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const latestTrips = await axios.get(`${BASE_URL}/getalltrips.php`);
            dispatch(setAllTrips(latestTrips.data));
            message.success('Trip added successfully!');
            form.resetFields();
            setTags([]);

        } catch (error) {
            message.error('Failed to add trip!');
        }
        form.resetFields();

    };

    // trip categories options 

    const catOptions = useMemo(() => {
        if (tripCategories) return tripCategories.map(item => ({ value: item.id, label: item.name }));
    }, [tripCategories]);


    // methods for select category fields

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleAddTag = () => {
        if (!inputValue) return;
        const newTags = [...tags, { name: inputValue, id: Date.now() }];
        setTags(newTags);
        setInputValue('');
    };

    const removeTag = (id) => {
        const newTags = tags.filter((item) => item.id !== id);
        setTags(newTags);
    }

    const handleEditorChange = (html) => {
        setEditorHtml(html);
    };


    if (limitLoading) {
        return <ContentLoader />
    }

    return (
        <div>
            {tripLimit && +tripLimit.trip_left < 0 && <PlanUpgradeMessage planName={limitLoading.name} />}
            <h2 className='text-[20px] font-medium text-txtDark'>Add New Trip</h2>
            <Form
                form={form}
                className='py-3'
                name="tripForm"
                onFinish={addNewTrip}
                layout="vertical"
            >

                <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-3">
                    <Form.Item
                        name="title"
                        rules={[{ required: true, message: 'Please enter the title' }]}
                    >
                        <Input placeholder="Trip Title" className='py-2 text-[14px]' />
                    </Form.Item>
                    <Form.Item
                        name="subtitle"
                        rules={[{ required: false, message: 'Please enter the subtitle' }]}
                    >
                        <Input placeholder="Trip Subtitle" className='py-2 text-[14px]' />
                    </Form.Item>
                    <Form.Item
                        name="category" // This name key is crucial
                        rules={[{ required: true, message: 'Please select a category' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Choose Category"
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={catOptions}
                        />
                    </Form.Item>
                </div>
                <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-3">
                    <Form.Item
                        name="count"
                        rules={[{ required: true, message: 'Please enter the count' }]}
                    >
                        <Input type='number' placeholder="Counter" className='py-2 text-[14px]' />
                    </Form.Item>
                    <Form.Item
                        name="startdate"
                        rules={[{ required: true, message: 'Please select the start date' }]}
                    >
                        <DatePicker className='py-2 text-[14px] w-full' placeholder='Start Date' />
                    </Form.Item>
                    <Form.Item
                        name="starttime"
                        rules={[{ required: true, message: 'Please select the start time' }]}
                    >
                        <TimePicker use12Hours format="h:mm a" className='py-2 text-[14px] w-full' placeholder='Start Time' />
                    </Form.Item>
                </div>
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-3">
                    <Form.Item
                        name="enddate"
                        rules={[{ required: true, message: 'Please select the end date' }]}
                    >
                        <DatePicker className='py-2 text-[14px] w-full' placeholder='End Date' />
                    </Form.Item>
                    <Form.Item
                        name="endtime"
                        rules={[{ required: true, message: 'Please select the end time' }]}
                    >
                        <TimePicker use12Hours format="h:mm a" className='py-2 text-[14px] w-full' placeholder='End Time' />

                    </Form.Item>

                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3 text-[14px]">
                    <Form.Item
                        name="details"
                        rules={[{ required: true, message: 'Please enter the details' }]}
                    >
                        <ReactQuill
                            theme="snow"
                            value={editorHtml}
                            onChange={handleEditorChange}
                        />
                    </Form.Item>
                    <div>

                        <Input
                            placeholder="Add a Trip Include"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleAddTag();
                                }
                            }}
                            suffix={
                                <Button icon={<PlusOutlined />} onClick={handleAddTag} disabled={!inputValue} />
                            }
                        />

                        <div>
                            {tags.map((tag, index) => (
                                <Tag
                                    key={index}
                                    className='cursor-pointer text-[14px] px-2 py-1 mt-3 mr-3 border bg-white border-themeBlue'
                                >
                                    <span>{tag.name}</span>
                                    <CloseCircleOutlined onClick={() => removeTag(tag.id)}
                                        className='text-themeRed cursor-pointer' />
                                </Tag>
                            ))}
                        </div>
                    </div>

                </div>
                <Form.Item>
                    {(tripLimit && (tripLimit.trip_left === null || +tripLimit.trip_left > 0)) && (
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            disabled={loading}
                            className='mt-4 text-[14px] sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeRed text-white bg-themeBlue h-auto inline font-medium px-3 py-1 duration-200'
                        >
                            Add Trip
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div >
    );
};

export default AddTrip;
