// BookingModal.js
import React, { memo } from 'react';
import { Modal } from 'antd';

const StatusModal = ({ isModalOpen, updateStatus, activeStatus, handleCancel, handleButtonClick, loading }) => {
    return (
        <Modal
            title="Set Booking Status"
            okText="Update Status"
            okButtonProps={{ className: "bg-themeRed text-white hover:bg-themeBlue font-medium", loading: loading }}
            cancelButtonProps={{ className: "bg-themeBlue text-white border-none hover:bg-themeRed font-medium" }}
            centered
            open={isModalOpen}
            onOk={updateStatus}
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <div className='text-center px-4 py-2 gap-3 flex justify-center items-center'>
                <div className="space-y-4">
                    <div>
                        <button
                            className={`bg-themeBlue p-1 m-2 rounded-md text-white border-none hover:bg-themeRed font-medium ${activeStatus === 'process' ? 'bg-themeRed' : ''
                                }`}
                            onClick={() => handleButtonClick('process')}
                        >
                            Process
                        </button>
                        <button
                            className={`bg-themeBlue p-1 m-2 rounded-md text-white border-none hover:bg-themeRed font-medium ${activeStatus === 'pending' ? 'bg-themeRed' : ''
                                }`}
                            onClick={() => handleButtonClick('pending')}
                        >
                            Pending
                        </button>
                        <button
                            className={`bg-themeBlue p-1 m-2 rounded-md text-white border-none hover:bg-themeRed font-medium ${activeStatus === 'completed' ? 'bg-themeRed' : ''
                                }`}
                            onClick={() => handleButtonClick('completed')}
                        >
                            Completed
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default memo(StatusModal);
