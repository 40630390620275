import { Button, Form, Input, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { } from 'react';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from "../../assets/images/logo.webp"
import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { setLogedInUser } from '../../store/features/user/userSlice';
import { setLogedInUser } from '../../utils/functions';
import usePost from '../../hooks/usePost';

const AdminLogin = () => {

    const [form] = useForm();
    const navigate = useNavigate();
    const { post, loading } = usePost();
    const { post: agencyCheckPost } = usePost();

    const loginAdmin = async (formData) => {
        window.scrollTo(0, 0);
        const response = await post('/login.php', formData);
        if (response && response.success) {
            const agencyCheck = await agencyCheckPost("/checkagency.php", { uid: response.user.uid });
            if (agencyCheck.data === 0) {
                setLogedInUser({ ...response.user, isadmin: agencyCheck.data });
                navigate("/createAgency");
            } else {
                setLogedInUser({ ...response.user, isadmin: agencyCheck.data });
                window.location.href = "/";
            }
            message.success("Logged In Successfully");
        } else {
            message.error("Invalid Credentials");
        }
    }

    return (
        <div>
            <div className='w-full h-[90vh] sm:h-screen flex justify-center items-center'>
                <div className='max-w-md w-full px-5'>
                    <div className='flex justify-center pb-6'>
                        <img src={logo} alt="logo" width={250} />
                    </div>
                    <div className='bg-white shadow-xl rounded-lg px-8 py-10'>
                        <h2 className="text-2xl font-semibold mb-6 text-txtDark">Admin Login</h2>
                        <Form
                            form={form}
                            name="admin"
                            initialValues={{ remember: true }}
                            onFinish={loginAdmin}
                        >
                            <Form.Item
                                name="username"
                                rules={[{ required: true, message: 'Please enter your username' }]}
                            >
                                <Input
                                    className='py-2 text-[16px]'
                                    prefix={<UserOutlined className='text-gray-400' />}
                                    placeholder="Enter your email"
                                    autoComplete='false'
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please enter your password!' }]}
                            >
                                <Input.Password
                                    className='py-2 text-[16px]'
                                    prefix={<LockOutlined className='text-gray-400' />}
                                    placeholder="Enter your password"
                                    autoComplete='false'
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="w-full bg-themeBlue text-[18px] py-1 font-medium h-full hover:bg-themeRed duration-300"
                                    disabled={loading}
                                    loading={loading}
                                >
                                    Login
                                </Button>
                            </Form.Item>
                            {/* <Link
                                to="/signup"
                                className="w-full bg-themeRed block text-center text-white rounded-md text-[18px] py-1 font-medium h-full hover:bg-themeBlue duration-300"
                            >
                                Signup
                            </Link> */}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLogin;
