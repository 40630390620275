import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allTrips: [],
    tripImages: [],
    tripPrices: [],
    tripCategories: [],
    activeTrip: null,
}

export const tripSlice = createSlice({
    name: "trip",
    initialState,
    reducers: {
        setAllTrips: (state, action) => {
            state.allTrips = action.payload;
        },
        setActiveTrip: (state, action) => {
            state.activeTrip = action.payload;
        },
        setTripImages: (state, action) => {
            state.tripImages = action.payload;
        },
        setTripPrices: (state, action) => {
            state.tripPrices = action.payload;
        },
        setTripCat: (state, action) => {
            state.tripCategories = action.payload;
        }
    }
});

export const { setAllTrips, setActiveTrip, setTripImages, setTripPrices, setTripCat } = tripSlice.actions;
export default tripSlice.reducer;
