import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allCustomers: [],
}

export const customersSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        setAllCustomers: (state, action) => {
            state.allCustomers = action.payload;
        },
    }
});

export const { setAllCustomers } = customersSlice.actions;
export default customersSlice.reducer;
