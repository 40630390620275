import React, { useState, useEffect, useCallback } from 'react';
import TripCard from '../../../components/tripCard/TripCard';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import ContentLoader from '../contentLoader/ContentLoader';
import axios from 'axios';
import { setAllTrips } from '../../../store/features/trip/tripSlice';

const ShowTrips = () => {
  const [trips, setTrips] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeAgency } = useSelector(state => state.agency);
  const dispatch = useDispatch();

  const fetchTrips = useCallback(async () => {
    try {
      const trips = await axios.get(`${process.env.REACT_APP_BASE_URL}/getalltrips.php/?aid=${activeAgency}`);
      dispatch(setAllTrips(trips.data));
      setTrips(trips.data);
    } catch (error) {
      console.log(error);
    }
  }, [activeAgency, dispatch]);

  useEffect(() => {
    fetchTrips();
  }, [fetchTrips]);


  useEffect(() => {
    if (trips && trips.length > 0) {
      const debouncedSearch = debounce(() => {
        const lowercasedQuery = searchQuery.toLowerCase();
        const filtered = trips.filter(trip =>
          trip.title.toLowerCase().includes(lowercasedQuery) && +trip.isdeleted === 0
        );
        setFilteredTrips(filtered);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      }, 300);

      debouncedSearch();
      return () => {
        debouncedSearch.cancel();
      };
    } else {
      setFilteredTrips([]);
    }
  }, [searchQuery, trips]);

  if (loading) {
    return <ContentLoader />;
  }

  return (
    <div>
      <h2 className='text-[20px] font-medium text-txtDark'>All Trips</h2>
      <div className="flex justify-end items-center">
        <input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          className='p-2 border-2 border-themeBlue bg-transparent rounded-md focus:outline-none'
          placeholder='Search Trip'
        />
      </div>
      <div>
        {trips && filteredTrips && filteredTrips.length > 0 ? filteredTrips.map((trip) => (
          <TripCard key={trip.id} trip={trip} />
        )) :
          <div className='text-center my-10'>
            <h2 className='text-[30px] font-medium animate-fadeIn'>No Trip Found</h2>
          </div>
        }
      </div>
    </div>
  );
}

export default ShowTrips;
