import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ShowTrips from '../mainComponents/showTrips/ShowTrips';
import AddTrip from '../mainComponents/addTrip/AddTrip';
import ContentNotFound from '../mainComponents/contentNotFound/ContentNotFound';
import TripDetail from '../mainComponents/tripDetail/TripDetail';
import EditTrip from '../mainComponents/editTrip/EditTrip';
import Bookings from '../mainComponents/bookings/Bookings';
import BookingDetail from '../mainComponents/bookingDetail/BookingDetail';
import TrashedTrip from '../mainComponents/trashedTrip/TrashedTrip';
import Customers from '../mainComponents/customers/Customers';
// import Subscription from '../mainComponents/subscription/Subscription';
// import Paypal from '../mainComponents/paypal/Paypal';
// import StripeComponent from '../mainComponents/stripeComponent/StripeComponent';
import Reporting from '../mainComponents/reporting/Reporting';

const MainContent = () => {
    return (
        <div className='mt-[60px] w-full'>
            <Content
                className='mb-20'
                style={{
                    margin: '24px 16px 0',
                    overflow: 'initial',
                }}
            >
                <div className='p-[15px] bg-white rounded-lg shadow-md'>
                    <Routes>
                        <Route path='/' element={<ShowTrips />} />
                        <Route path='/addtrip' element={<AddTrip />} />
                        <Route path='/tripDetail/:id' element={<TripDetail />} />
                        <Route path='/edittrip/:id' element={<EditTrip />} />
                        <Route path='/bookings' element={<Bookings />} />
                        <Route path='/trashedtrip' element={<TrashedTrip />} />
                        <Route path='/customers' element={<Customers />} />
                        <Route path='/bookingdetail/:id' element={<BookingDetail />} />
                        <Route path='/reporting' element={<Reporting />} />
                        {/* <Route path='/subscription' element={<Subscription />} /> */}
                        {/* <Route path='/paypal' element={<Paypal />} /> */}
                        {/* <Route path='/payment' element={<StripeComponent />} /> */}
                        <Route path='/*' element={<ContentNotFound />} />
                    </Routes>
                </div>
            </Content>
        </div>
    );
};

export default MainContent;
