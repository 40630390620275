import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriptionCart: {},
}

const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        addSubCart: (state, action) => {
            state.subscriptionCart = action.payload;
        }
    }
});

export const { addSubCart } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;