import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from "./features/user/userSlice";
import tripReducer from "./features/trip/tripSlice";
import bookingReducer from "./features/booking/bookingSlice";
import customersReducer from "./features/customers/customersSlice";
import agencyReducer from "./features/agency/agencySlice";
import subscriptionReducer from "./features/subscription/scubscriptionSlice";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';


const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ['user', "subscription", "booking"] // Specify the states you want to persist
}

const rootReducer = combineReducers({
    user: userReducer,
    trip: tripReducer,
    booking: bookingReducer,
    customer: customersReducer,
    agency: agencyReducer,
    subscription: subscriptionReducer,

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;