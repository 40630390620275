import React from 'react'
import { Link } from 'react-router-dom'


const ContentNotFound = () => {

    return (
        <div className='flex justify-center items-center py-5'>
            <div className='text-center'>
                <h2 className='text-themeRed font-bold text-[40px]'>404</h2>
                <h3 className='text-txtDark font-normal text-[30px] mb-4'>Not Found</h3>
                <Link to={-1} className='text-[16px] text-white border-none sm:text-[18px]block rounded-md leading-normal shadow-lg hover:bg-themeBlue bg-themeRed font-medium px-3 py-1'>Go Back</Link>
            </div>
        </div>
    )
}

export default ContentNotFound