import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import ContentLoader from '../contentLoader/ContentLoader';
import CustomerCard from '../../../components/customerCard/CustomerCard';
import { setAllCustomers } from '../../../store/features/customers/customersSlice';
import axios from 'axios';

const Customers = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredCustomer, setFilteredCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const { activeAgency } = useSelector(state => state.agency);
    const dispatch = useDispatch();

    const fetchCustomers = useCallback(async () => {
        if (activeAgency)
            try {
                const customers = await axios.get(`${process.env.REACT_APP_BASE_URL}/getallcustomers.php/?aid=${activeAgency}`);
                dispatch(setAllCustomers(customers.data));
                setCustomers(customers.data);
                setLoading(false);
            } catch (error) {

            }
    }, [activeAgency, dispatch]);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    // Function to handle debounced search
    useEffect(() => {
        const debouncedSearch = debounce(() => {
            if (customers) { // Check if customers is defined
                const lowercasedQuery = searchQuery.toLowerCase();
                const filtered = customers.filter(customer =>
                    customer.fname.toLowerCase().includes(lowercasedQuery)
                );
                setFilteredCustomers(filtered);

            }
        }, 300);

        debouncedSearch();

        // Cleanup function to cancel the debounce on component unmount
        return () => {
            debouncedSearch.cancel();
        };
    }, [searchQuery, customers]); // Depend on searchQuery and customers

    if (loading) {
        return <ContentLoader />
    }

    return (
        <div>
            <h2 className='text-[20px] font-medium text-txtDark'>All Customers</h2>
            <div className="flex justify-end items-center mb-4">
                <input
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    type="text"
                    className='p-2 border-2 border-themeBlue bg-transparent rounded-md focus:outline-none'
                    placeholder='Search Customer'
                />
            </div>
            <div>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                    {filteredCustomer.map((customer) => (
                        <CustomerCard key={customer.id} customer={customer} />
                    ))}
                </div>
                {filteredCustomer.length === 0 &&
                    <div className='text-center my-10'>
                        <h2 className='text-[30px] font-medium animate-fadeIn'>No Customer Found</h2>
                    </div>
                }
            </div>
        </div>
    );
}

export default Customers;
