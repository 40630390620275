import React from 'react'

const CustomerCard = ({customer}) => {

    return (
        <div className='border-2 border-[#E9E9E9] rounded-lg cursor-pointer hover:bg-[#f5f5f5] duration-300'>
            <div className='p-3'>
                <p className='text-[20px] text-themeBlue font-medium mb-1 capitalize'>Name : {customer.fname + " " + customer.lname}</p>
                <p className='text-[15px] font-medium mb-1 capitalize'>Phone NO : {customer.phone}</p>
                <p className='text-[15px] font-medium mb-1'>Email : {customer.email}</p>
            </div>
        </div>
    )
}

export default CustomerCard