import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './main/Dashboard';
import AdminLogin from './components/adminLogin/AdminLogin';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from './hooks/useFetch';
import { useEffect, useMemo, useState } from 'react';
import { setAllTrips, setTripCat, setTripImages, setTripPrices } from './store/features/trip/tripSlice';
import Preloader from './components/preloader/Preloader';
import { setAllBookings } from './store/features/booking/bookingSlice';
import { setAllCustomers } from './store/features/customers/customersSlice';
import Signup from './components/signup/Signup';
import VerifyEmail from './components/verifyEmail/VerifyEmail';
// import CreateAgency from './components/createAgency/CreateAgency';
import axios from 'axios';
import { setActiveAgency, setAgencyCurrenSub, setMyAgency } from './store/features/agency/agencySlice';
import { getLogedInUser } from './utils/functions';

function App() {

  const logedInUser = useMemo(() => getLogedInUser(), []);
  const { data: tripImages, error: errorTripImages } = useFetch("/tripimages.php");
  const { data: prices, error: errorPrices } = useFetch("/getprices.php");
  const { data: allCategory, error: errorAllCategory } = useFetch("/getallcat.php");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { activeAgency } = useSelector(state => state.agency);


  useEffect(() => {
    if (tripImages && prices && allCategory) {
      dispatch(setTripImages(tripImages));
      dispatch(setTripPrices(prices));
      dispatch(setTripCat(allCategory));
      setLoading(false);
    } else if (errorTripImages || errorPrices || errorAllCategory) {
      setError('Failed to fetch initial data');
      setLoading(false);
    }
  }, [prices, tripImages, allCategory, errorTripImages, errorPrices, errorAllCategory, dispatch]);

  // if user is logged in and agency is created then get user agency 
  useEffect(() => {
    (async () => {
      if (logedInUser && logedInUser.isadmin === 1) {
        try {
          const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/getagency.php`, { uid: logedInUser.uid });
          dispatch(setMyAgency(resp.data.data));
          const activeAgency = resp.data.data[0].id;
          dispatch(setActiveAgency(activeAgency));
        } catch (error) {
          console.log(error);
          setError('Failed to fetch agency data');
        }
      }
    })();
  }, [dispatch, logedInUser]);

  useEffect(() => {
    (async () => {
      if (activeAgency) {
        try {
          const trips = await axios.get(`${process.env.REACT_APP_BASE_URL}/getalltrips.php/?aid=${activeAgency}`);
          dispatch(setAllTrips(trips.data));
          const bookings = await axios.get(`${process.env.REACT_APP_BASE_URL}/getallbooking.php/?aid=${activeAgency}`);
          dispatch(setAllBookings(bookings.data));
          const customers = await axios.get(`${process.env.REACT_APP_BASE_URL}/getallcustomers.php/?aid=${activeAgency}`);
          dispatch(setAllCustomers(customers.data));
          if (logedInUser) {
            const currentSub = await axios.post(`${process.env.REACT_APP_BASE_URL}/getsubbyagency.php/`, { aid: activeAgency, uid: logedInUser.uid });
            dispatch(setAgencyCurrenSub(currentSub.data.data));
          }
        } catch (error) {
          console.log(error);
          setError('Failed to fetch data for active agency');
        }
      }
    })();
  }, [activeAgency, dispatch, logedInUser ]);

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Routes>
      <Route path='/adminlogin' element={logedInUser && logedInUser.isadmin === 1 ? <Navigate to="/" /> : <AdminLogin />} />
      <Route path='/signup' element={logedInUser && logedInUser.isadmin === 1 ? <Navigate to="/" /> : <Signup />} />
      {/* <Route path='/createagency' element={logedInUser && logedInUser.isadmin === 0 && <CreateAgency />} /> */}
      <Route path='/verifyemail' element={logedInUser ? <Navigate to="/" /> : <VerifyEmail />} />
      <Route path='/*' element={logedInUser && logedInUser.isadmin === 1 ? <Dashboard /> : <Navigate to="/adminlogin" />} />
    </Routes>
  );
}

export default App;
