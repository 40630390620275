import { Header } from 'antd/es/layout/layout'
import React, { useState } from 'react'
import { UserOutlined, MenuOutlined } from '@ant-design/icons';
import { Avatar, Drawer, Modal, message } from 'antd';
import Sidebar from '../sidebar/Sidebar';
// import { useDispatch } from 'react-redux';
// import { setLogedInUser } from '../../store/features/user/userSlice';
import { setLogedInUser } from '../../utils/functions';
// import { useNavigate } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
// import { setActiveAgency } from '../../store/features/agency/agencySlice';

const Topbar = () => {

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const { myAgency } = useSelector(state => state.agency);
    // const [selectedAgency, setSelectedAgency] = useState('');

    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        logout();
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };
    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const logout = () => {
        setLogedInUser(null);
        message.success("Logout Successfully");
        window.location.href = "/";
    }

    // const handleAgencyChange = async (activeAgency) => {
    //     setSelectedAgency(activeAgency);
    //     dispatch(setActiveAgency(activeAgency));
    // };

    return (
        <div className='relative z-10'>
            <Header className='bg-themeBlue text-white fixed top-0 w-[-webkit-fill-available] px-7'>
                <div className="flex gap-3 justify-between items-center h-full">
                    <div className='text-[20px]'>
                        <MenuOutlined className='md:hidden' onClick={toggleDrawer} />
                    </div>
                    <div className='flex items-center gap-3'>
                        <Avatar size="large" icon={<UserOutlined />} />
                        <p className='text-[20px] font-medium leading-normal'>Admin</p>
                        <button onClick={showModal} className='text-[16px] sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeBlue border-2 border-themeBlue hover:border-themeRed bg-themeRed h-auto inline font-medium px-3 py-1 duration-200'>Logout</button>
                        {/* <select
                            value={selectedAgency}
                            onChange={(e) => handleAgencyChange(e.target.value)}
                            className='cursor-pointer text-themeBlue bg-white text-[16px] p-2 border-2 border-themeBlue bg-transparent rounded-md focus:outline-none'
                        >
                            {myAgency.map((agency) => (
                                <option key={agency.id} value={agency.id}>
                                    {agency.title}
                                </option>
                            ))}
                        </select> */}
                    </div>
                </div>
            </Header>
            <Modal
                okText="Yes"
                okButtonProps={{ className: "bg-themeRed text-white hover:bg-themeBlue font-medium" }}
                cancelButtonProps={{ className: "bg-themeBlue text-white border-none hover:bg-themeRed font-medium" }}
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className='text-center px-4 py-2 flex justify-center items-center'>
                    <div className="space-y-4">
                        <LogoutOutlined className='text-6xl text-themeRed' />
                        <p className='text-2xl font-medium'>Do you want to logout?</p>
                    </div>
                </div>
            </Modal>
            <Drawer
                placement="left"
                closable={false}
                onClose={toggleDrawer}
                open={drawerVisible}
                width={200}
            >
                <Sidebar closeDrawer={closeDrawer} />
            </Drawer>
        </div>
    )
}

export default Topbar