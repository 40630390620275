import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Table, DatePicker, Select, Button, Tag } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import ExcelJS from 'exceljs'; // Import exceljs
import { formatDate } from '../../../utils/functions';

dayjs.extend(isBetween);

const { RangePicker } = DatePicker;
const { Option } = Select;

const Reporting = () => {
    const { allBookings } = useSelector(state => state.booking);
    const { allTrips } = useSelector(state => state.trip);

    const [filteredBookings, setFilteredBookings] = useState([]);
    const [filters, setFilters] = useState({
        dateRange: null,
        tripId: null,
        guestType: null,
        status: null,
        payStatus: null,
    });

    const filterData = useCallback(() => {
        let data = [...allBookings];

        if (filters.dateRange) {
            const [start, end] = filters.dateRange;
            data = data.filter(booking => {
                const bookingDate = dayjs(booking.bdate);
                return bookingDate.isBetween(start, end, null, '[]');
            });
        }

        if (filters.tripId && filters.tripId !== 'all') {
            data = data.filter(booking => booking.tid === filters.tripId);
        }

        if (filters.guestType && filters.guestType !== 'all') {
            if (filters.guestType === 'guest') {
                data = data.filter(booking => booking.customerid === '0');
            } else if (filters.guestType === 'registered') {
                data = data.filter(booking => booking.customerid !== '0');
            }
        }

        if (filters.status && filters.status !== 'all') {
            data = data.filter(booking => booking.status === filters.status);
        }

        if (filters.payStatus && filters.payStatus !== 'all') {
            data = data.filter(booking =>
                (filters.payStatus === 'paid' && booking.orderid) ||
                (filters.payStatus === 'unpaid' && !booking.orderid)
            );
        }

        setFilteredBookings(data);
    }, [allBookings, filters]);

    useEffect(() => {
        filterData();
    }, [filters, allBookings, filterData]);

    const handleFilterChange = (key, value) => {
        setFilters(prev => {
            const newFilters = { ...prev, [key]: value };
            if (key === 'payStatus' && value === undefined) {
                newFilters.payStatus = null;
            }
            return newFilters;
        });
    };

    const calculateTotalAmount = () => {
        return filteredBookings.reduce((sum, booking) => sum + parseFloat(booking.amount), 0);
    };

    const generateExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Report');

        // Define header row
        const headers = ['Booking Date', 'Trip', 'Customer Name', 'Phone', 'Email', 'Amount', 'Status'];
        worksheet.addRow(headers);

        // Add data rows
        filteredBookings.forEach(booking => {
            worksheet.addRow([
                dayjs(booking.bdate).format('MMM-DD-YYYY hh:mmA'),
                booking.title,
                `${booking.fname} ${booking.lname}`,
                booking.phone,
                booking.email,
                `£${parseFloat(booking.amount).toFixed(2)}`,
                booking.status,
            ]);
        });

        // Add totals row
        worksheet.addRow([
            '',
            '',
            '',
            '',
            'Total Amount',
            `£${calculateTotalAmount().toFixed(2)}`,
            '',
        ]);

        // Apply header styles
        const headerRow = worksheet.getRow(1);
        headerRow.font = {
            bold: true,
            size: 14, // Increase the font size
        };
        headerRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF00' }, // Yellow color
        };
        headerRow.alignment = {
            vertical: 'middle', // Vertical alignment
            horizontal: 'center', // Horizontal alignment
            wrapText: true, // Wrap text if necessary
        };

        // Apply alignment and borders to simulate padding
        headerRow.eachCell({ includeEmpty: true }, (cell) => {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        // Set column widths
        worksheet.columns = [
            { width: 20 },
            { width: 35 },
            { width: 25 },
            { width: 15 },
            { width: 30 },
            { width: 15 },
            { width: 15 },
        ];

        // Freeze the header row
        worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];

        // Write the workbook to file
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'report.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const columns = [
        {
            title: 'Booking Date',
            dataIndex: 'bdate',
            key: 'bdate',
            render: date => formatDate(date.split(" ")[0]),
        },
        {
            title: 'Trip',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Customer Name',
            dataIndex: 'fname',
            key: 'fname',
            render: (text, record) => `${record.fname} ${record.lname}`,
        },
        {
            title: 'User Type',
            render: (text, record) => record.customerid === "0" ? "Guest" : "Customer",
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => {
                const statusColorMap = {
                    pending: 'geekblue',
                    process: 'orange',
                    completed: 'green',
                };

                return (
                    <Tag color={statusColorMap[status] || 'default'}>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                    </Tag>
                );
            }

        },
        {
            title: 'Pay Status',
            key: 'pay status',
            render: (_, record) => (
                <Tag color={record.orderid ? "green" : "red"}>
                    {record.orderid ? "Paid" : "UnPaid"}
                </Tag>
            )
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: amount => `£${parseFloat(amount).toFixed(2)}`,
        },
    ];

    return (
        <div className="container mx-auto p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                    <label className="block font-bold mb-2">Booking Date Range</label>
                    <RangePicker
                        onChange={dates => handleFilterChange('dateRange', dates)}
                        className="w-full"
                    />
                </div>
                <div>
                    <label className="block font-bold mb-2">Trips</label>
                    <Select
                        placeholder="Select a trip"
                        onChange={value => handleFilterChange('tripId', value)}
                        className="w-full"
                        value={filters.tripId || 'all'}
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        <Option value="all">All Trips</Option>
                        {allTrips.map(trip => (
                            <Option key={trip.id} value={trip.id}>
                                {trip.title}
                            </Option>
                        ))}
                    </Select>

                </div>
                <div>
                    <label className="block font-bold mb-2">User Type</label>
                    <Select
                        placeholder="Select customer type"
                        onChange={value => handleFilterChange('guestType', value)}
                        className="w-full"
                        value={filters.guestType || 'all'}
                        allowClear
                    >
                        <Option value="all">All Types</Option>
                        <Option value="guest">Guest</Option>
                        <Option value="registered">Customer</Option>
                    </Select>
                </div>
                <div>
                    <label className="block font-bold mb-2">Booking Status</label>
                    <Select
                        placeholder="Select status"
                        onChange={value => handleFilterChange('status', value)}
                        className="w-full"
                        value={filters.status || 'all'}
                        allowClear
                    >
                        <Option value="all">All Statuses</Option>
                        <Option value="pending">Pending</Option>
                        <Option value="process">Process</Option>
                        <Option value="completed">Completed</Option>
                    </Select>

                </div>
                <div>
                    <label className="block font-bold mb-2">Pay Status</label>
                    <Select
                        placeholder="Select pay status"
                        onChange={value => handleFilterChange('payStatus', value)}
                        className="w-full"
                        value={filters.payStatus || 'all'}
                        allowClear
                    >
                        <Option value="all">All Statuses</Option>
                        <Option value="paid">Paid</Option>
                        <Option value="unpaid">Unpaid</Option>
                    </Select>
                </div>
            </div>

            <div id="report-table" className="overflow-x-auto bg-white p-4 shadow rounded-lg">
                <Table
                    columns={columns}
                    dataSource={filteredBookings}
                    pagination={{ pageSize: 15 }}
                    rowKey="id"
                    size='small'
                />
                <div className="text-right mt-4 text-[20px]">
                    <p className="font-bold">Total Bookings : {filteredBookings.length}</p>
                    <p className="font-bold">Total Amount: £{calculateTotalAmount().toFixed(2)}</p>
                </div>
            </div>

            <div className="text-right mt-4">
                <Button type="primary" size='large' onClick={generateExcel} className='bg-themeBlue hover:opacity-[0.9] transition-opacity duration-300'>
                    Export to Excel
                </Button>
            </div>
        </div>
    );
};

export default Reporting;
