import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    logedInUser: null,
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLogedInUser: (state, action) => {
            state.logedInUser = action.payload;
        }
    }
});

export const { setLogedInUser } = userSlice.actions;
export default userSlice.reducer;
