import Sider from 'antd/es/layout/Sider'
import React from 'react'
import logo from "../../assets/images/logo.webp"
import {
    AppstoreAddOutlined,
    FundViewOutlined,
    ContainerOutlined,
    DeleteOutlined,
    UserOutlined,
    FileSearchOutlined
} from '@ant-design/icons';
import { Link, NavLink } from 'react-router-dom';


const Sidebar = ({ closeDrawer }) => {

    const closeSideBar = () => {
        if (closeDrawer) {
            closeDrawer();
        }
        return
    }

    const items = [
        {
            key: 1,
            label: "Show Trips",
            icon: <FundViewOutlined />,
            link: "/"
        },
        {
            key: 2,
            label: "Add Trip",
            icon: <AppstoreAddOutlined />,
            link: "/addtrip"
        },
        {
            key: 3,
            label: "Bookings",
            icon: <ContainerOutlined />,
            link: "/bookings",

        },
        {
            key: 4,
            label: "Trashed Trips",
            icon: <DeleteOutlined />,
            link: "/trashedtrip",

        },
        {
            key: 5,
            label: "Customers",
            icon: <UserOutlined />,
            link: "/customers",

        },
        {
            key: 6,
            label: "Reporting",
            icon: <FileSearchOutlined />,
            link: "/reporting",

        }
        // ,
        // {
        //     key: 6,
        //     label: "Subscription",
        //     icon: <ClockCircleOutlined />,
        //     link: "/subscription",

        // }
    ]

    return (
        <div>
            <Sider className='overflow-auto h-screen fixed left-0 top-0 bottom-0 bg-white drop-shadow-lg'>
                <div className="demo-logo-vertical">
                    <Link to="/">
                        <img src={logo} alt="logo" className='p-4' />
                    </Link>
                </div>
                <ul className='list-none'>
                    {items.map((item) => (
                        <li key={item.key} className='mx-[4px] my-3' onClick={closeSideBar}>
                            <NavLink to={item.link} className={({ isActive }) => `${isActive ? "bg-themeBlue shadow-md text-white" : "text-gray-600 "} flex pl-[24px] pr-[16px] py-[7px] rounded-lg gap-2 duration-0 text-[18px]`}>
                                <span>{item.icon}</span>
                                <span>{item.label}</span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </Sider>
        </div>
    )
}

export default Sidebar