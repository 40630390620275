import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allBookings: [],
    activeBookingTrip: null,
}

export const bookingSlice = createSlice({
    name: "booking",
    initialState,
    reducers: {
        setAllBookings: (state, action) => {
            state.allBookings = action.payload;
        },
        setActiveBookingTrip: (state, action) => {
            state.activeBookingTrip = action.payload;
        }
    }
});

export const { setAllBookings, setActiveBookingTrip } = bookingSlice.actions;
export default bookingSlice.reducer;
