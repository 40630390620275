import React, { memo } from 'react';
import { formatDate, formatTime } from '../../utils/functions';
import { Link } from 'react-router-dom';

const TripCard = ({ trip }) => {

    return (
        <div className="bg-white border-2 shadow-sm border-[#E9E9E9] mt-4 rounded-lg overflow-hidden duration-300">
            <Link to={`/tripdetail/${trip.id}`} key={trip.id}>
                <div className="px-4 py-2 hover:bg-[#f5f5f5]">
                    <div className="flex items-center justify-between">
                        <h2 className="text-lg font-semibold text-gray-800">{trip.title}</h2>
                        <h2 className="text-[16px] font-semibold text-gray-800">Status {+trip.isactive === 1 ? "Active" : "Non Active"}</h2>
                    </div>
                    <div className="flex items-center justify-between mt-2">
                        <div className="flex items-center text-sm text-gray-600">
                            <span className="mr-2">Start Date:</span>
                            <span className="font-medium">{formatDate(trip.startdate)}</span>
                        </div>
                        <div className="flex items-center text-sm text-gray-600">
                            <span className="mr-2">End Date:</span>
                            <span className="font-medium">{formatDate(trip.enddate)}</span>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-2 border-t-2 border-[#f3f3f3] py-2">
                        <div className="flex items-center text-sm text-gray-600">
                            <span className="mr-2">Start Time:</span>
                            <span className="font-medium">{formatTime(trip.starttime)}</span>
                        </div>
                        <div className="flex items-center text-sm text-gray-600">
                            <span className="mr-2">End Time:</span>
                            <span className="font-medium">{formatTime(trip.endtime)}</span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default memo(TripCard);
