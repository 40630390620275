import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined, ArrowLeftOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Signup = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const goBack = () => {
        navigate(-1);
    }

    const registerUser = async (formData) => {
        setLoading(true);
        if (formData.password.length < 8) {
            message.error("Password Length should be 8 characters");
            setLoading(false);
            return;
        }
        try {
            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const payload = { ...formData, isadmin: 1 }
            const response = await axios.post(`${BASE_URL}/addnewuser.php`, payload);
            if (response.status === 200) {
                message.success('Please Verify Email');
                navigate("/verifyemail");
                setLoading(false);
            }
        } catch (error) {
            message.error('Email Allready Registered');
            setLoading(false);
        }
    };

    return (
        <div className='w-full h-screen flex justify-center items-center'>
            <div className='max-w-md w-full px-5'>
                <div className='py-2'>
                    <button onClick={goBack} className='bg-themeRed text-white text-[18px] w-10 h-10 rounded-full hover:bg-themeBlue duration-200'>
                        <ArrowLeftOutlined />
                    </button>
                </div>
                <div className='bg-white shadow-lg rounded-lg px-8 py-10'>
                    <h2 className="text-2xl font-semibold mb-6">Register</h2>
                    <Form
                        name="SignupForm"
                        initialValues={{ remember: true }}
                        onFinish={registerUser}
                    >
                        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2">
                            <Form.Item
                                name="fname"
                                rules={[{ required: true, message: 'Please enter your First Name' }]}
                            >
                                <Input
                                    className='py-2 text-[16px]'
                                    prefix={<UserOutlined className='text-gray-400' />}
                                    placeholder="First Name" />
                            </Form.Item>
                            <Form.Item
                                name="lname"
                                rules={[{ required: true, message: 'Please enter your Last Name!' }]}
                            >
                                <Input
                                    className='py-2 text-[16px]'
                                    prefix={<UserOutlined className='text-gray-400' />}
                                    placeholder="Last Name" />
                            </Form.Item>
                        </div>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please enter your email!' }]}
                        >
                            <Input
                                className='py-2 text-[16px]'
                                prefix={<MailOutlined className='text-gray-400' />}
                                placeholder="Your email" />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            rules={[{ required: true, message: 'Please enter your Phone!' }]}
                        >
                            <Input
                                className='py-2 text-[16px]'
                                prefix={<PhoneOutlined className='text-gray-400' />}
                                placeholder="Your Phone" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please enter your password!' }]}
                        >
                            <Input.Password
                                className='py-2 text-[16px]'
                                prefix={<LockOutlined className='text-gray-400' />}
                                placeholder="Enter your password" />

                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                disabled={loading}
                                className="w-full bg-themeBlue text-[18px] py-1 font-medium h-full hover:bg-themeRed duration-300">

                                {loading ? "loading..." : "Register"}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <p className="text-gray-500 text-sm mt-4 text-center">Already have an account? <Link to="/adminlogin" className="text-themeRed hover:underline">Login now</Link></p>
            </div>
        </div>
    );
};

export default Signup;
